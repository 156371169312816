let buttons = document.querySelectorAll('button.indicator-toggle');
let items = document.querySelectorAll('div.indicator-item');
let itemsTarget = document.querySelectorAll('div.indicator-target');
let itemsButtonClose = document.querySelectorAll('button.indicator-close');

buttons.forEach(function(button) {
  button.addEventListener('click', function(e) {
    e.preventDefault();

    // Check if the clicked item is already active
    let parentItem = this.closest('.indicator-item');
    let isActive = parentItem.classList.contains('active');

    // Remove 'active' class from all items and targets
    items.forEach(function(item) {
      item.classList.remove('active');
    });

    itemsTarget.forEach(function(target) {
      target.classList.remove('active', 'target-show');
    });

    // Add 'active' class to the clicked item only if it wasn't active
    if (!isActive) {
      parentItem.classList.add('active');

      // Add 'active' and 'target-show' classes to the target of the clicked item
      let targetItem = parentItem.querySelector('.indicator-target');
      targetItem.classList.add('active', 'target-show');

      // Remove 'active' class from all close buttons
      itemsButtonClose.forEach(function(button) {
        button.classList.remove('active');
      });

      // Add 'active' class to the close button of the clicked item
      let closeButton = parentItem.querySelector('.indicator-close');
      closeButton.classList.add('active');
    }
  });
});

itemsButtonClose.forEach(function(closeButton) {
  closeButton.addEventListener('click', function() {
    if (this.classList.contains('active')) {
      // Remove 'active' and 'target-show' classes from all targets
      itemsTarget.forEach(function(target) {
        target.classList.remove('active', 'target-show');
      });

      // Remove 'active' class from all close buttons
      itemsButtonClose.forEach(function(btn) {
        btn.classList.remove('active');
      });
    }
  });
});
